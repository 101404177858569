'use client';
import { Button, SkeletonText } from '@antcorefinance/ui';
import { useWallet } from '@aptos-labs/wallet-adapter-react';
import { motion } from 'framer-motion';
import { CheckCircle2, Lock } from 'lucide-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Countdown from './CountDown';
import { checkAndRegisterUser, checkRegistrationStatus, getCompletedTasks } from './events';
import FactionStats from './factionStats';
import { ProductFaq } from './faq';
import FactionJoin from './joinFaction';
import TaskCard from './taskCard';
import { AntcIcon } from '@antcorefinance/ui/icons/AntcIcon';
import { message } from 'antd';
import Loading from '@/app/loading';

const tasks = [
  {
    id: 1,
    title: (
      <span>
        Follow Antcore on{' '}
        <svg
          width="22px"
          color="#fff"
          fill="#fff"
          style={{ display: 'inline', verticalAlign: 'sub' }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
        >
          <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"></path>
        </svg>
      </span>
    ),
    totalPoints: 1000,
    number: '01',
    tasks: [
      {
        id: 1,
        title: (
          <span>
            1. Login and Follow Antcore on{' '}
            <svg
              width="22px"
              color="#fff"
              fill="#fff"
              style={{ display: 'inline', verticalAlign: 'sub' }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
            >
              <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"></path>
            </svg>
          </span>
        ),
        points: 500,
      },
      {
        id: 2,
        title: '2. Like and Repost the Campaign Post',
        points: 500,
      },
    ],
  },
  {
    id: 2,
    title: 'Complete on-chain tasks on Antcore',
    totalPoints: 2000,
    number: '02',
    tasks: [
      {
        id: 3,
        title: '1. Mint and Join the Antcore Colony',
        points: 500,
      },
      {
        id: 4,
        title: '2. Stake APT-ANTC LP Tokens',
        points: 1500,
      },
    ],
  },
  {
    id: 3,
    title: 'Allocate APT and Join Antcore Workers',
    points: 8001,
    number: '03',
    tasks: [
      {
        id: 5,
        title: '1. Allocate APT For ANTC',
        points: 8000,
      },
      {
        id: 6,
        title: '2. Meet Antcore Workers ',
        points: 1,
      },
    ],
    countdown: 3600,
  },
];
export default function MainCard() {
  const [completedSubTasks, setCompletedSubTasks] = useState<number[]>([]);
  const { account, isLoading, connected } = useWallet();

  const [loading, setLoading] = useState(true);
  const [registerLoading, setRegisterLoading] = useState(true);
  const [isRegistered, setIsRegistered] = useState(false);

  const [isClient, setIsClient] = useState(false);
  // const [message, setMessage] = useState<string | null>(null);

  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    const fetchRegistrationStatus = async () => {
      if (account?.address) {
        setRegisterLoading(true);
        try {
          const status = await checkRegistrationStatus(account.address);
          setIsRegistered(status.isRegistered);
        } catch (error) {
          console.error('Error checking registration status:', error);
        } finally {
          setRegisterLoading(false);
        }
      }
    };

    fetchRegistrationStatus();
  }, [account?.address]);

  // Handle registration via form submission
  const handleRegister = async () => {
    if (!account?.address) {
      message.error('Please connect to a wallet.');
      return;
    }

    try {
      const response = await checkAndRegisterUser(account?.address);

      if (response.error) {
        message.error(response.error);
        return;
      }

      if (response.success || response.isRegistered) {
        setIsRegistered(true);

        if (response.isRegistered) {
          message.success('You are already registered for the event.');
        } else {
          message.success('You have successfully registered for the event.');
        }
      }
    } catch (error: any) {
      message.error('An error occurred during registration.');
    }
  };

  const toggleFAQ = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: 'What is the purpose of this testnet campaign?',
      answer:
        'The purpose of this testnet campaign is to engage users in our ecosystem, allowing them to participate in tasks and compete in factions to earn ANTC tokens.',
    },
    {
      question: 'How do I join a faction?',
      answer:
        'You can join a faction by selecting one of the options on the landing page: Red Swarm, Blue Nest, or Purple Hive.',
    },
    {
      question: 'What are the rewards for the winning faction?',
      answer:
        'The winning faction will share 1 million ANTC tokens, distributed among the top contributors.',
    },
    {
      question: 'How do I complete tasks?',
      answer:
        'Tasks can be completed by following the instructions provided in the task section. These tasks may include tweeting, participating in community discussions, and more.',
    },
    {
      question: 'When does the campaign end?',
      answer:
        'The campaign will run until a specific date, which will be announced soon. Stay tuned for updates!',
    },
  ];

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    const fetchCompletedTasks = async () => {
      if (account?.address) {
        setLoading(true);
        try {
          const response = await getCompletedTasks(account.address);

          // Check if response contains an error
          if ('error' in response) {
            console.error('Error fetching completed tasks:', response.error);
            setCompletedSubTasks([]); // Clear the tasks in case of error
          } else {
            setCompletedSubTasks(response); // response should be number[] here
          }
        } catch (error: any) {
          console.error('Error fetching completed tasks:', error.message);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false); // Set loading to false if account.address is not available
        setRegisterLoading(false);
      }
    };

    // Clear completed tasks when account.address changes
    setCompletedSubTasks([]);

    fetchCompletedTasks();
  }, [account?.address]);

  if (!isClient) {
    return null;
  }

  const isPreviousTasksCompleted = (completedSubTasks: any) => {
    const requiredTaskIds = [1, 2, 3, 4];
    return requiredTaskIds.every((id) => completedSubTasks.includes(id));
  };

  const renderButton = () => {
    if (registerLoading) {
      return (
        <Button
          variant="antc"
          className="m-auto flex w-full cursor-not-allowed !rounded-xl !bg-[#238636] px-4 py-2 font-mono !text-base font-bold text-white"
        >
          <p className="font-bold"> Register Now </p>
          <Countdown />{' '}
        </Button>
      );
    }
    if (isRegistered) {
      return (
        <Button
          variant="antc"
          disabled
          className="m-auto flex w-full cursor-not-allowed !rounded-xl !bg-[#238636] px-4 py-2 font-mono !text-base font-bold text-white"
        >
          <p className="flex flex-row gap-2 font-bold">
            <CheckCircle2 color="#2ab246"> </CheckCircle2> Already Registered
          </p>
        </Button>
      );
    }
    return (
      <Button
        variant="antc"
        onClick={handleRegister}
        className="m-auto flex w-full !rounded-xl !bg-[#238636] px-4 py-2 font-mono !text-base font-bold text-white"
      >
        <p className="font-bold"> Register Now </p>
        <Countdown />
      </Button>
    );
  };

  return (
    <>
      <div style={{ background: '#010d12' }} className={`min-h-screen bg-[#010d12] text-white`}>
        <div className="m-auto w-full max-w-7xl pt-14">
          {/* <div className="flew-row m-auto mb-14 flex items-center justify-center gap-4">
            <AntcIcon width="20px" size="100px" className="w-[120]"></AntcIcon>

            <p className="items-center font-mono text-xl font-semibold italic"> TESTNET CAMPAIGN</p>
          </div> */}

          <header className="card-border relative mx-2 mb-16 flex min-h-[250px] max-w-screen-xl flex-col items-center justify-center overflow-hidden rounded-xl bg-cardBlack p-0 text-center shadow-2xl sm:min-h-[338px] sm:p-6 md:min-h-[200px]">
            <p
              className="relative mx-auto max-w-6xl font-mono text-lg md:text-2xl"
              data-aos="fade"
              data-aos-delay="600"
            >
              <motion.p
                className={`mt-1 px-2 font-mono text-2xl font-bold uppercase sm:mt-4 sm:text-4xl`}
              >
                Three Factions, One Million Antc. Your Move.
              </motion.p>
            </p>

            <p
              className="relative mx-auto mt-6 max-w-5xl px-4 font-mono text-sm sm:text-base"
              data-aos="fade"
              data-aos-delay="600"
            >
              Complete tasks, earn rewards, and rise through the ranks. Join Antcore Workers and
              compete for your share of 1 million antcore tokens. Every action counts—be part of the
              winning faction!
            </p>
          </header>

          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="mx-auto px-2">
            <FactionStats></FactionStats>
          </motion.div>

          <section className="px-2 py-9 pb-4 text-center">
            <FactionJoin></FactionJoin>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="mx-auto grid grid-cols-1 gap-8 sm:grid-cols-3"
            ></motion.div>
          </section>
          <div className={`min-h-screen bg-[#010d12]`}>
            <div className="font-roboto-mono m-auto max-w-7xl pl-2 pt-20 text-3xl font-bold text-gray-100">
              GETTING STARTED 🔗
            </div>

            <div className="m-auto flex w-full max-w-7xl flex-wrap justify-start gap-6 px-2 pt-10">
              {false ? (
                <>
                  {tasks.slice(0, 2).map((task, index) => (
                    <div
                      key={index} // Add a unique key, either task.id or index if no id
                      className="card-shadow card-border relative min-h-[230px] max-w-[100%] flex-[0_0_100%] rounded-md bg-cardBlack p-4 sm:max-w-[calc(50%-12px)] sm:flex-[0_0_calc(50%-12px)] md:max-w-[calc(33.3%-16px)] md:flex-[0_0_calc(33.3%-16px)]"
                    >
                      <div className="flex h-full flex-col justify-between">
                        <div className="flex flex-col">
                          <div className="contest-card mb-2 mt-2 !max-w-[40px] font-tiny text-gray-100">
                            <SkeletonText />
                          </div>
                          <p className="mt-2 !max-w-[90%] text-lg text-gray-100">
                            <SkeletonText />
                          </p>
                        </div>
                        <div>
                          <div className="border-antc -mx-4 mb-4"></div>
                          <div className="mt-4 flex flex-row items-center justify-between">
                            <SkeletonText />
                            <div
                              className="group flex cursor-pointer flex-row text-gray-100 hover:text-white"
                              // onClick={handleStartQuest}
                            >
                              <SkeletonText />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {tasks.slice(0, 2).map((task) => (
                    <TaskCard
                      key={task.id}
                      taskId={task.id}
                      taskTitle={task.title}
                      taskPoints={task.totalPoints}
                      subTasks={task.tasks}
                      completedSubTasks={completedSubTasks}
                      taskNumber={task.number}
                    />
                  ))}
                </>
              )}

              {isLoading || loading ? (
                <>
                  <div className="card-shadow card-border relative min-h-[230px] max-w-[100%] flex-[0_0_100%] rounded-md bg-cardBlack p-4 sm:max-w-[calc(50%-12px)] sm:flex-[0_0_calc(50%-12px)] md:max-w-[calc(33.3%-16px)] md:flex-[0_0_calc(33.3%-16px)]">
                    <div className="flex h-full flex-col justify-between">
                      <div className="flex flex-col">
                        <div className="contest-card mb-2 mt-2 !max-w-[40px] font-tiny text-gray-100">
                          <SkeletonText />
                        </div>
                        <p className="mt-2 text-lg text-gray-100">
                          {!isLoading ? <SkeletonText /> : <SkeletonText />}
                        </p>
                      </div>
                      <div>
                        <div className="border-antc -mx-4 mb-4"></div>
                        <div className="mt-4 flex flex-row items-center justify-between">
                          <SkeletonText />
                          <div
                            className="group flex cursor-pointer flex-row text-gray-100 hover:text-white"
                            // onClick={handleStartQuest}
                          >
                            <SkeletonText />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : account?.address && completedSubTasks.length !== 0 ? (
                <>
                  {isPreviousTasksCompleted(completedSubTasks) ? (
                    <TaskCard
                      key={tasks[2].id}
                      taskId={tasks[2].id}
                      taskTitle={tasks[2].title}
                      taskPoints={tasks[2].points}
                      subTasks={tasks[2].tasks}
                      completedSubTasks={completedSubTasks}
                      taskNumber={tasks[2].number}
                    />
                  ) : (
                    <div className="card-shadow card-border relative min-h-[230px] max-w-[100%] flex-[0_0_100%] rounded-md bg-cardBlack p-4 sm:max-w-[calc(50%-12px)] sm:flex-[0_0_calc(50%-12px)] md:max-w-[calc(33.3%-16px)] md:flex-[0_0_calc(33.3%-16px)]">
                      <div className="flex h-full flex-col justify-between">
                        <div className="flex flex-col">
                          <div className="contest-card mb-2 mt-2 !max-w-[40px] font-tiny text-gray-100">
                            <SkeletonText />
                          </div>
                          <p className="mt-2 !max-w-[90%] text-lg text-gray-100">
                            <SkeletonText />
                          </p>
                        </div>
                        <div>
                          <div className="border-antc -mx-4 mb-4"></div>
                          <div className="mt-4 flex flex-row items-center justify-between">
                            <SkeletonText />
                            <div
                              className="group flex cursor-pointer flex-row text-gray-100 hover:text-white"
                              // onClick={handleStartQuest}
                            >
                              <SkeletonText />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Overlay to indicate that previous tasks need to be completed */}
                      <div className="absolute inset-0 z-10 flex items-center justify-center rounded-md bg-black bg-opacity-75">
                        <div className="flex flex-col items-center justify-center gap-4 font-bold text-white">
                          <Lock />
                          Complete previous tasks to unlock
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="card-shadow card-border relative min-h-[230px] max-w-[100%] flex-[0_0_100%] rounded-md bg-cardBlack p-4 sm:max-w-[calc(50%-12px)] sm:flex-[0_0_calc(50%-12px)] md:max-w-[calc(33.3%-16px)] md:flex-[0_0_calc(33.3%-16px)]">
                    <div className="flex h-full flex-col justify-between">
                      <div className="flex flex-col">
                        <div className="contest-card mb-2 mt-2 !max-w-[40px] font-tiny text-gray-100">
                          <SkeletonText />
                        </div>
                        <p className="mt-2 !max-w-[90%] text-lg text-gray-100">
                          <SkeletonText />
                        </p>
                      </div>
                      <div>
                        <div className="border-antc -mx-4 mb-4"></div>
                        <div className="mt-4 flex flex-row items-center justify-between">
                          <SkeletonText />
                          <div
                            className="group flex cursor-pointer flex-row text-gray-100 hover:text-white"
                            // onClick={handleStartQuest}
                          >
                            <SkeletonText />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Overlay to indicate that previous tasks need to be completed */}
                    <div className="absolute inset-0 z-10 flex items-center justify-center rounded-md bg-black bg-opacity-75">
                      <div className="flex flex-col items-center justify-center gap-4 font-bold text-white">
                        <Lock />
                        Complete previous tasks to unlock
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="font-roboto-mono m-auto max-w-7xl pl-2 pt-20 text-3xl font-bold text-gray-100">
              ANTCORE EVENT ENGINE 🏁
            </div>

            <div className="m-auto mb-10 flex w-full max-w-7xl flex-wrap justify-start gap-6 px-2 pt-10">
              <div className="card-border min-h-[330px] max-w-md rounded-md bg-cardBlack p-4">
                <div className="flex h-full flex-col justify-between">
                  <div className="flex h-full flex-col">
                    <div className="contest-card mb-2 font-mono text-gray-100"></div>
                    <div className="flex h-full flex-col justify-between text-lg text-gray-100">
                      <p className="self-start font-semibold">
                        Antcore Event Engine powers faction events, delivering dynamic and
                        competitive challenges.
                      </p>
                      <div className="flex flex-col space-y-1">
                        <ul className="ml-1 flex flex-col gap-1">
                          <li>
                            {' '}
                            <strong> 🥇 1st Place: </strong> 60% of the total points
                          </li>
                          <li>
                            {' '}
                            <strong> 🥈 2nd Place: </strong> 30% of the total points
                          </li>
                          <li>
                            <strong> 🥉 3rd Place: </strong> 10% of the total points
                          </li>
                        </ul>
                      </div>
                      <p className="mb-2 self-start"></p>
                    </div>
                  </div>
                  <div>
                    <div className="border-antc -mx-4 mb-4" />

                    <div className="flex flex-row items-center justify-between">
                      {renderButton()}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <ProductFaq faqSlug={'antcxswap'}></ProductFaq> */}
            {/* <div className="mx-auto my-10">
              {faqs.map((faq, index) => (
                <div key={index} className="mb-4">
                  <button
                    className={`card-border w-full rounded-t-lg bg-cardBlack p-4 text-left ${
                      activeIndex === index ? 'rounded-b-none' : 'rounded-lg'
                    } focus:outline-none`}
                    onClick={() => toggleFAQ(index)}
                  >
                    <div className="flex items-center justify-between">
                      <span className="font-bold">{faq.question}</span>
                      <span>{activeIndex === index ? '-' : '+'}</span>
                    </div>
                  </button>
                  {activeIndex === index && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: '100%' }}
                      transition={{ duration: 0.3 }}
                      className="card-border rounded-b-lg bg-cardBlack p-4"
                    >
                      <p>{faq.answer}</p>
                    </motion.div>
                  )}
                </div>
              ))}
            </div> */}
          </div>
        </div>
        <footer className="justify-centerborder-t flex flex-col items-center border-[#141b2b] bg-cardBlack py-6 text-gray-300">
          <div className="flew-row m-auto flex items-center justify-center gap-3">
            <AntcIcon width="25px" className="w-[120]"></AntcIcon>

            <div className="flex flex-row gap-2">
              {' '}
              <p className="items-center font-mono text-xl font-semibold"> / Antcore Portal</p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
